.color_banner{
    height: 50%;
    display: flex;
}

.slide_left{
    animation: right-to-left .5s ease-in forwards;
    margin-left: auto; 
    margin-right: 0;
}

.slide_right{
    animation: left-to-right .5s ease-in forwards;
}

@keyframes left-to-right{
    0%{
        width: 0;
    }
    100%{
        width: 100%;
    }
}

@keyframes right-to-left{
    0%{
        width: 0;
    }
    100%{
        width: 100%;
    }
}

@keyframes fade_in {
    0%{
        font-size: min(10vh,10vw);
        opacity: 0;
    }
    100%{
        font-size: min(10vh,10vw);
        opacity: 1;
    }
}

@keyframes fade_in1 {
    0%{
        visibility: visible;
        opacity: 0;
    }
    100%{
        visibility: visible;
        opacity: 1;
    }
}


.home_logo{
    width: 100%;
    height: 50vh;
}

.logo_text {
    align-self: flex-end;
    font-size: min(10vh,10vw);
    font-weight: bold;
    text-decoration: none;
    opacity: 0;
    color: white; 
    text-shadow: 6px 4px 5px rgba(59,59,59,0.67);
    display:block;
}

.top_text{
    padding-left: 5vw;
    font-size: 0;
    animation: fade_in .5s .6s ease-in forwards;
}

.bottom_text{
    animation: fade_in .5s 1.1s ease-in forwards;
    margin-left: auto; 
    margin-right: 0;
    padding-right: 5vw;
}

.home_body{
    animation: fade_in1 .5s 1.5s ease-in forwards;
    visibility: hidden;
}

.home_image{
    max-width: 95%;
    border-radius: 10px;

}

.home_grid_container{
    padding-top: 3em;
}

.overview_text {
    font-size: 20px;
}

.overview_wrapper{
    display: flex;
    align-items: center;
}

.how_to{
    max-width: 60%;
    margin: auto;
    text-align: center;
    padding: 1em 2em 1em 2em;
}

.li_text{
    font-size: 50px !important;
}

.home_about{
    padding: 2em 2em 2em 2em;
    text-align: center;
    width: 80%;
    border-radius: 30px;
    margin: auto;
}

.home_list{
    width: 25%;
    margin: auto
}

.center{
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 800px){
    .how_to{
        max-width: 90%;
        margin: 2em 1em 2em 1em;
    }
    
    .home_list{
        width: 75%;

    }

    .logo_text {
        align-self: flex-end;
        font-weight: bold;
        text-decoration: none;
        opacity: 0;
        color: white; 
        text-shadow: 6px 4px 5px rgba(59,59,59,0.67);
        display:block;
        margin: auto;
        padding: 0;
    }

    .home_logo{
        width: 100%;
        height: 40vh;
    }

    .home_about{
        padding: 0;
        margin-left: 5vw;
        margin-right: 5vw;
        padding: 1em 1em 1em 1em;
    }

    .home_image{
        max-width: 100%;
        border-radius: 0;
    }

    .home_grid_container{
        padding: 0;
    }
}