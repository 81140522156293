@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,700;1,800&display=swap');

.campaign_wrapper{
    max-width: 60%;
    margin: auto;
}

.loader-container {
    top: 80px;
    left: 0;
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    position: absolute;
}

.campaign_see_submissions{
    margin: auto;
    text-align: center;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: cyan transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.campaign_form_container{
    max-width: 80%;
    text-align: center;
    margin: auto;
    margin-bottom: 2em;
}

.campaign_text_box{
    line-height: 2em !important;
    padding-top: 16.5px;
    padding-bottom: 16.5px;
    padding-left: 14px;
    padding-right: 0;
    height: 1.4375em !important;
    width: calc(100% - 14px) !important;
}

.my_campaign_form_container{
    background-color: white;
    width: 60%;
    height: 80%;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 0px rgba(0, 255, 255, 0.8);
    margin-left: 100vw;
    text-align: center;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.del_wrap{
    text-align: right;
}

.campaign_title{
    padding-top: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 56px;
    margin-bottom: 0;
    margin-top: 0.5em;
    text-align: center;
}

.campaign_description{
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    max-width: 100%;
    margin: auto;
    font-size: 20px;
    padding-bottom: 2vh;
    padding-top: 2vh;
    text-align: center;
}

.campaign_auto_resize_disabled{
    margin-top: 8px;
    width: 100%;
    min-height: 30px;
    font-size: 1rem;
    border-radius: 4px;
    text-align: center;
    vertical-align: middle;
    border-color: rgba(118, 118, 118, 0.3);
    line-height: 2em;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    resize: none;
}

.campaign_top_of_row{
}

.campaign_verify_cat{
    font-size: 24px;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
}

.campaign_verify_item{
    font-family: 'Poppins', sans-serif;
    padding-top: 1em;
}

.campaign_auto_resize{
    margin-top: 8px;
    width: 100%;
    min-height: 30px;
    font-size: 1rem;
    border-radius: 4px;
    text-align: center;
    vertical-align: middle;
    border-color: rgba(118, 118, 118, 0.3);
    line-height: 2em;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    resize: none;;
}

.campaign_auto_resize:hover{
    border-color: black;
}

.campaign_auto_resize_disabled::-webkit-input-placeholder {
    line-height: 2em;
}
.campaign_auto_resize_disabled:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    line-height: 2em;
}
.campaign_auto_resize_disabled::-moz-placeholder { /* Mozilla Firefox 19+ */
    line-height: 2em;
}
.campaign_auto_resize_disabled:-ms-input-placeholder { /* Internet Explorer 10+ */
    background:transparent;
    line-height: 2em;
}
.campaign_auto_resize::-webkit-input-placeholder { /* WebKit browsers */
    line-height: 2em;
}
.campaign_auto_resize:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    line-height: 2em;
}
.campaign_auto_resize::-moz-placeholder { /* Mozilla Firefox 19+ */
    line-height: 2em;
}
.campaign_auto_resize:-ms-input-placeholder { /* Internet Explorer 10+ */
    line-height: 2em;
}

::-webkit-resizer{
    background:transparent;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.make_form_container::-webkit-scrollbar {
    display: none;
}

@keyframes faded_in {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.campaign_verify_page{

}

@media only screen and (max-width: 800px){
    .campaign__form_container{
        position: relative;
        height: 100vh;
        width: 100vw;
        margin: 0 0 0 0;
        animation: faded .2s ease-in forwards;
        border-radius: 0;
    }
    .campaign__title{
        font-size: 32px !important;
        font-weight: 600 !important;
    }

    .campaign__description{
        font-size: 2.5vh !important;
    }
    .campaign_top_of_row{
        padding-top: 20px;
    }
    .close_icon_wrapper{
        padding: 16px 16px 0 0;
    }
    .campaign_form_container{
        margin-bottom: 1em;
    }
    .campaign_verify_page{
        text-align: center;
    }
    .campaign_wrapper{
        max-width: 100%;
    }
    .campaign_description{
        margin-left: 1vw;
        margin-right: 1vw;
        font-size: 16px;
    }
    .del_wrap{
        text-align: right;
    }
}