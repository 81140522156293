.box {
    background: rgb(59, 59, 59);
    width: 100%;
}

.container {
    padding: 20px;
    justify-content: center;
    margin: 0 auto;
}

.row {
    grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
    grid-gap: 20px;
    justify-content: center;
    text-align: center;
}

.footerLink {
    color: lightblue;
    margin-bottom: 20px;
    font-size: 18px;
    font-size: min(18px, 4vw);
    text-decoration: none;
}

.footerLink:hover{
    color: cyan;
    transition: 200ms ease-in;
    font-size: min(20px, 6vw);}

.heading {
    font-size: 24px;
    color: #fff;
    margin-bottom: 40px;
    font-weight: bold;
}

.footerText{
    color: white;
    font-size: min(18px, 4vw);
}

@media only screen and (max-height: 725px){
    .box {
        display: box;
    }
}