.header{
    background: rgb(59, 59, 59);
    height: 80px;
    padding: 0 1.5rem;
    transition: 0.3s ease all;
}

.header_content{
    width: 100%;
    overflow: hidden;
    color: cyan;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 100;
}

.header_content_logo{
    font-size: 2rem;
    font-weight: bold;
    text-decoration: none;
    color: cyan;
}

.header_content_nav{
    top: 0;
    right: 100%;
    bottom: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(59,59,59,0.9);
    backdrop-filter: blur(2px);
    transform: translate(0);
    transition: 0.3s ease transform;
}

.header_content_nav_ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-top: 0;
}

.header_content_nav_li {
    text-decoration: none;
    color: lightblue;
    padding: 0.75rem 1.25rem;
    border-radius: 15px;
    transition: 0.3s ease all
}

.nav_label {
    text-decoration: none;
    color: inherit;
    padding: 0.75rem 1.25rem;
    border-radius: 5px;
    transition: 0.3s ease all;
}

.nav_label:hover {
    background: rgba(cyan, 0.1);
    color: cyan;
    font-size: 20px;
}

.nav_label:active {
    border-radius: calc(16);
    background: linear-gradient(rgba(cyan, 0.1), rgba(cyan, 0.2));
}

.header_content_nav_li:hover {
    background: rgba(cyan, 0.1);
}

.header_content_nav_li:active {
    border-radius: 21px;
    background: linear-gradient(rgba(cyan, 0.1), rgba(cyan, 0.2));
}

.header_button{
    cursor: pointer;
    outline: none;
    padding: 0.75rem 1.25rem;
    border-radius: 5px;
    font-size: 1rem;
    font-family: inherit;
    background: lightblue;
    color: rgb(59, 59, 59);
    background: cyan;
    border: 1px solid transparent;
    transition: 0.3s ease all;
}

.isMenu{
    transform: translate(100%);
}

.header_button:hover{
    border-color: rgb(59, 59, 59);
    background: rgba(cyan, 0.1);
}

.header_button:active{
    border-color: rgb(59, 59, 59);
    background: linear-gradient(rgba(lightblue, 0.2), rgba(lightblue, 0.3));
    color: lightblue;
}

.header_content_toggle{
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 2rem;
    transition: 0.3s ease all;
    position: relative;
}

.header_content_toggle:hover{
    color: lightblue
}

@media only screen and (min-width: 800px){
    .header{
        padding: 0 2rem;
    }
    .header_content_nav{
        transform: none;
        flex-direction: row;
        background: transparent;
        width: auto;
        height: 100%;
        position: static;
    }
    .header_content_nav_ul {
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
        margin-right: calc(0.5rem + 30px);
    }
    .header_content_toggle{
        display: none;
    }
}