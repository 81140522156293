.loader-container {
    top: 80px;
    left: 0;
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    position: absolute;
}

.campaign_see_submissions{
    margin: auto;
    text-align: center;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: cyan transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

.submissions_wrapper{
    margin: auto;
    max-width: 60%;
}
.sad_image{
    max-height: 50vh;
    padding-top: 5vh;
}

.campaign_title_singular{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    margin-bottom: 0;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 800px){
    .submissions_wrapper{
        max-width: 100%;
    }
}