@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,700;1,800&display=swap');


.make_hello_container{
    height: 100vh;
    width: 100vw;
    position: sticky;
    top: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 100;
    margin-top: -100vh;
}

.invisi_block{
    height: 10vh;
    width: 100%;
    visibility: none;
}

.make_hello_form_container{
    max-width: 80%;
    text-align: center;
    margin: auto;
    margin-bottom: 2em;
}

.make_hello_text_box{
    line-height: 2em !important;
    padding-top: 16.5px;
    padding-bottom: 16.5px;
    padding-left: 14px;
    padding-right: 0;
    height: 1.4375em !important;
    width: calc(100% - 14px) !important;
}

.make_form_container{
    background-color: white;
    width: 60%;
    height: 80%;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 0px rgba(0, 255, 255, 0.8);
    margin-left: 100vw;
    text-align: center;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.make_hello_wrapper{
    padding: 0vh 5vw 0vh 5vw;
    font-family: 'Poppins', sans-serif;
}

.make_hello_checkbox{
    width: 10em
}

.make_title{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 40px;
}

.make_description{
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    font-size: 20px;
    padding-bottom: 2vh;
    padding-top: 2vh;
}

.make_hello_auto_resize_disabled{
    margin-top: 8px;
    width: 100%;
    min-height: 30px;
    font-size: 1rem;
    border-radius: 4px;
    text-align: center;
    vertical-align: middle;
    border-color: rgba(118, 118, 118, 0.3);
    line-height: 2em;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    resize: none;
}

.close_icon_wrapper{
    text-align: right;
    padding: 0 0 0 0;
}

.close_icon{
    width: 2em;
}

.top_of_row{
}

.verify_cat{
    font-size: 24px;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
}

.verify_item{
    font-family: 'Poppins', sans-serif;
    padding-top: 1em;
}

.make_hello_auto_resize{
    margin-top: 8px;
    width: 100%;
    min-height: 30px;
    font-size: 1rem;
    border-radius: 4px;
    text-align: center;
    vertical-align: middle;
    border-color: rgba(118, 118, 118, 0.3);
    line-height: 2em;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    resize: none;
}

.make_hello_auto_resize:hover{
    border-color: black;
}

.make_hello_auto_resize_disabled::-webkit-input-placeholder { /* WebKit browsers */
    line-height: 2em;
}
.make_hello_auto_resize_disabled:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    line-height: 2em;
}
.make_hello_auto_resize_disabled::-moz-placeholder { /* Mozilla Firefox 19+ */
    line-height: 2em;
}
.make_hello_auto_resize_disabled:-ms-input-placeholder { /* Internet Explorer 10+ */
    line-height: 2em;
}
.make_hello_auto_resize::-webkit-input-placeholder { /* WebKit browsers */
    line-height: 2em;
}
.make_hello_auto_resize:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    line-height: 2em;
}
.make_hello_auto_resize::-moz-placeholder { /* Mozilla Firefox 19+ */
    line-height: 2em;
}
.make_hello_auto_resize:-ms-input-placeholder { /* Internet Explorer 10+ */
    line-height: 2em;
}

.bottom_form{
    /* padding-bottom: 2em; */
    margin-top: 30px;
}

.buy_coffee{
    padding-top: 1em;
    max-width: 60%;
    margin: auto
}

::-webkit-resizer{
    background:transparent;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.make_form_container::-webkit-scrollbar {
    display: none;
  }

@keyframes faded_in {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes ltr {
    80%{
        margin-left: 15vw;
    }
    90%{
        margin-left: 22vw;
    }
    100%{
        margin-left: 20vw;
    }
}

.faded{
    animation: faded_in .2s ease-in forwards;
}

.verify_page{

}

.faded_delay{
    animation: ltr .4s .2s ease-in forwards;
}

.hide_on_pc{
    display: none;
}

.make_err_msg{
    position: relative;
}

@media only screen and (max-width: 800px){
    .make_hello_container{
        position: sticky;
    }
    .invisi_block{
        display: none;
    }
    .make_form_container{
        position: relative;
        height: 100vh;
        width: 100vw;
        margin: 0 0 0 0;
        animation: faded .2s ease-in forwards;
        border-radius: 0;
    }
    .make_title{
        font-size: 32px !important;
        font-weight: 600 !important;
    }

    .make_description{
        font-size: 2.5vh !important;
    }
    .top_of_row{
        padding-top: 20px;
    }
    .close_icon_wrapper{
        padding: 16px 16px 0 0;
    }
    .make_hello_form_container{
        margin-bottom: 1em;
    }
    .verify_page{
        text-align: left;
    }
    .hide_on_pc{
        display: block;
    }
}