.login_wrapper{
    position: absolute;
    top: 0;
    background-image: url("https://img.freepik.com/free-vector/hand-painted-background-violet-orange-colours_23-2148427578.jpg?w=2000");
    background-repeat: no-repeat;
    background-size: cover ;
    width: 100vw;
    height: 100vh;
    z-index: 200;
}

.close_icon{
    margin-top: 0.5em;
    margin-left: 0.5em;
}

.login_form_icon{
    margin-top: 0.4em;
}

.form_field_wrapper{
    text-align: center;
    width: 60%;
    margin: auto;
    margin-top: 13em;
}

.login_title{
    padding-top: 0.5em;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 56px;
    margin-bottom: 0;
}

.login_text_box{
    width: 60%;
} 

.icon_box{
    margin: 1em;
}

.password_control{
    margin: auto;
    max-width: 80%;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.password_reset_button_wrapper{
    width: 80%;
    padding-top: 1em;
}

.login_password_reset{
    margin-top: 0.5em;
    text-decoration: none;
    text-align: right;
}

.login_err{
}

@keyframes l-t-r {
    100%{
        margin-left: 0vw;
    }
}

.login_form_wrapper{
    position: relative;
    margin-left: -50vw;
    height : 100vh;
    width: 50vw;
    background-color: white;
    animation: l-t-r 0.5s cubic-bezier(0.3,-0.05, 0.3, 1.01) forwards;
}

@media only screen and (max-width: 800px){
    .login_form_wrapper{
        margin-left: 0;
        animation: none;
        width: 100vw;
    }
    .form_field_wrapper{
        width: 100%;
        margin-top: 0;
        box-shadow: none !important;
    }
    .login_text_box{
        width:85%
    }
    .login_err{
        margin-top: 2em;
    }
}