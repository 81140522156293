.nf_wrapper{
    max-width: 60%;
    margin: auto;
    text-align: center;
}

.nf_text {
    font-size: 20px;
}

.nf_image{
    border-radius: 10px;
    max-width: 90%
}

.nf_title {
    padding-top: 0.5em;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 56px;
    margin-bottom: 0;
    margin-top: 0.5em;
}

@media only screen and (max-width: 800px){
    .nf_wrapper{
        max-width: 95%;
    }
    .contact_title{
        padding-top: 0;
    }
    .nf_image{
        max-width: 100%
    }
}