.submit_container{
    max-width: 100%;
    padding-top: 0vh;
    text-align: center;
    border: transparent;
}

.loader-container {
    top: 80px;
    left: 0;
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    position: absolute;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: cyan transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.submit_prompt{
    margin-top: .6em;
}

.submit_field_button{
    height: 40px;
}

.submit_field{
    padding-top: 2vh;
}

.submit_text_resize {
    width: 100%;
    min-height: 30px;
    font-size: 1rem;
    border-radius: 4px;
    text-align: center;
    vertical-align: middle;
    border-color: rgba(118, 118, 118, 0.3);
    line-height: 2em;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    resize: none;
}

.submit_title{
    max-width: 60%;
    margin: auto;
}

.submit_greeting{
    font-size: 18px;
}

.success_message{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
}

.submit_form_container{
    max-width: 60%;
    margin: auto;
    display: flex;
}

.error_msg{
    margin-top: 2vh;
    margin-left: 20%;
    margin-right: 20%;
}

.submit_title{
    padding-top: 3vh;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    margin-bottom: 0;
}

.auto_resize{
    width: 100%;
    min-height: 10vh;
}

.submit_instruction{
    margin-bottom: 0;
    padding-bottom: 0;;
}

.submit_button{
    width: 40%; 
    height: 32px;
    font-weight: bold
}

.submit_content{
    padding-top: 2vh;
}

@media only screen and (max-width: 700px){
    .submit_form_container{
        max-width: 90%;
    }

    .error_msg{
        margin-left: 5%;
        margin-right: 5%;
    }

    .submit_container{
        padding-top: 0;
    }

    .submit_button{
        width: 80%;
        height: max(5vh, 3rem);
    }

    .submit_title{
        max-width: 90%;
    }

    .submit_field{
        padding-top: 3vh;
    }

    .submit_content{
        padding-top: 0vh;
    }

    .success_message{
        position: static;
        top: 0;
        left: 0;
        -webkit-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
    }

}